/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
.tenantloader {
  color: var(--orng);
  margin-left: 45% !important;
  margin-top: 20% !important;
}

.tenant-view-table {
  width: 90% !important;
  padding: 10px !important;
  margin: 10px !important;
  border: 1px solid black !important;
}

.tenant-view-table tr {
  padding: 5px !important;
  border: 1px solid black !important;
}

.tenant-view-table tr:hover,
tr.active {
  background-color: rgb(129, 129, 129);
  color: white;
  cursor: pointer;
  display: table-row !important;
}

.addtenantbtn {
  margin-left: 80%;
}

.addlabel {
  margin-left: 15%;
  color: var(--orng);
}

.addData {
  color: var(--orng);
}

.v-btn {
  background-color: var(--orng) !important;
  border-color: var(--orng) !important;
  outline: none !important;
  overflow: hidden !important;
  box-shadow: none !important;
}

.v-btn:focus,
.v-btn:hover,
.v-btn:active {
  border-color: var(--orng) !important;
  outline: none !important;
  overflow: hidden !important;
  box-shadow: none !important;
}

.d-block {
  display: block !important;
}

.form-control:focus {
  border-color: var(--mediumOrng) !important;
  box-shadow: 0 0 0 0.2rem var(--lightOrng) !important;
}
