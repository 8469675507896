/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
.collapsible-container {
  justify-content: left;
  margin-top: 5px;
}

.collapsible {
  color: white;
  background-color: rgb(251, 106, 9);
  cursor: pointer;
  text-decoration: white;
  padding: 5px;
  width: 100%;
  border: none;
  text-align: left;
  outline: thick;
  font-size: 20px;
}

button.collapsible {
  text-align: left;
  padding-left: 2%;
}

/*.innerCollapse .collapsible-content {
  background-color: #f8ebe4 !important;
}*/

.collapsible-active,
.collapsible:hover {
  background-color: rgb(218, 91, 6);
  color: white(0, 0, 0);
}

.collapsible-content {
  width: 100%;
  padding: 10px;
  display: none;

  text-align: left;
  border: 1px solid var(--mediumOrng);
  border-top: 0 !important;
}

.collapsible i.fas {
  float: right;
  margin-right: 5px;
  margin-top: 5px;
}
.innerCollapse-button {
  /* background-image: linear-gradient(to bottom, #fef5f1, #ddcece) !important; */
  background-color: #f6f6f6 !important;
  color: var(--text-col) !important;
  padding: 5px !important;
  font-size: 14px !important;
  font-weight: 500;
  border: 1px solid var(--mediumOrng);
}

.middleCollapse-button {
  /* background-image: linear-gradient(to bottom, #fef5f1, #ddcece) !important; */
  background-color: #d7d6d56b !important;
  color: var(--text-col) !important;
  padding: 5px !important;
  font-size: 14px !important;
  font-weight: 500;
}
.uppermiddleCollapse-button {
  /* background-image: linear-gradient(to bottom, #fef5f1, #ddcece) !important; */
  background-color: #b6b5b578 !important;
  color: var(--text-col) !important;
  padding: 5px !important;
  font-size: 14px !important;
  font-weight: 500;
}
