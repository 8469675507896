/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
.rating-data-box {
  display: flex;
  flex-grow: 1;
  flex-flow: column;
  margin: auto;
  text-align: center;
  padding: 0;
  height: 76%;
  border-radius: 5px;
  background-color: white;
  min-width: 45%;
  position: fixed;
  overflow-x: hidden;
  overflow-y: auto;
}

h3 {
  color: var(--orng);
}

h5 {
  color: var(--orng);
}

th {
  color: var(--orng);
  background-color: none !important;
  border-bottom: none;
}

.rating-data-display-container {
  margin: auto;
}

.orangeCol {
  color: var(--orng);
}

td {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.field-label {
  text-align: left;
}

.field-value {
  text-align: left;
}

/*.table-hover tbody tr:hover {
  
  
  cursor:pointer;
  background-color:var(--lightOrng);
}*/

.table thead th {
  border-bottom: none !important;
  background-color: none !important;
}
.table td,
.table th {
  border-top: none !important;
  font-size: 14px !important;
}
.w-698 {
  width: 698px !important;
}
