/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */

.expression {
  overflow-x: auto;
  overflow-wrap: normal;
}

.codeSection {
  background-color: var(--lightOrng);
  padding: 10px;
  border-radius: 10px;
}

.spinnerCol {
  color: var(--orng);
}

.rules-spinner {
  text-align: center;
  min-height: 30px !important;
}

.pagination-margin ul {
  margin: 10px 0 0 !important;
}
