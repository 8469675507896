/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
.loader {
  color: var(--orng);
  margin-left: 45%;
  margin-top: 30%;
}

.hierarchy-box {
  flex-grow: 1;
  margin: auto;
  padding: 15px;
  height: 55em;
  width: 90%;
  border: 3px solid black;
  border-radius: 5px;
  background-color: white;
  box-shadow: 1px 1px 2px 2px #ccc;
  min-width: 400px;
}

.hierarchy-data-box {
  margin-top: 10px;
  position: fixed;
  height: 75%;
  width: 45%;
  overflow-x: hidden;
  overflow-y: auto;
}

.has-search .form-control {
  padding-left: 2.375rem;
  border: 0.5px solid var(--mediumOrng) !important;
}

.has-search .form-control-search {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: var(--orng);
}
