/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
.api-ref-box {
  display: flex;
  background-color: rgb(245, 245, 245);
  flex-flow: row wrap;
  border-radius: 10px;
  border: 5px ridge var(--orng);
  box-shadow: 4px 4px 6px #ccc;
  width: 80%;
  height: auto;
  margin: auto;
  margin-top: 3%;
  align-items: flex-start;
  min-width: 430px;
}

.component-box {
  width: 100%;
  display: flex;
  margin: 10px;
  flex-flow: row wrap;
}

.api-ref-title {
  width: 100%;
  background-color: white;
  border-bottom: 3px solid black;
  box-shadow: 0px 2px 0px 0px #ccc;
  padding: 10px;
}

h1 {
  text-shadow: 1px 1px 2px gray;
}

.btn-link {
  color: var(--text-col) !important;
  width: 100%;
  text-align: left !important;
  outline: none !important;
  border: 0;
  outline: none !important;
  box-shadow: none !important;
}

.btn-link:hover,
.btn-link:active,
.btn-link:visited,
.btn-link:focus {
  text-decoration: none !important;
}

.list-group-item {
  padding: 5px 15px !important;
  color: var(--text-col);
  cursor: pointer;
  border: 1px solid #faccb6 !important;
}

.list-group-item.active,
.list-group-item.focused {
  background-color: var(--mediumOrng) !important;
  border-color: var(--mediumOrng) !important;
  color: black !important;
}

.hierarchyContainer {
  width: 95%;
  margin: auto;
  padding: 10px 10px;
}

.vertical-split {
  display: flex;
}

.vertical-section {
  flex: 1;
}

.tab-content .tab-pane {
  display: none;
}

.tab-content .active {
  display: block;
}

.select-label {
  margin-right: 5px;
}

.reference-options {
  margin: 10px;
}

.customSelect {
  background-color: #fffcfb;
  border-width: 3px;
  border-color: #ffede0;
  color: var(--text-col);
  outline: 0 !important;
}

.col-centered {
  float: none;
  margin: 0 auto;
}

.hierarchyContainer nav a {
  color: var(--text-col) !important;
}

.hierarchyContainer nav a.active {
  background: linear-gradient(#fee0c8, #fff);
  border-color: var(--mediumOrng) var(--mediumOrng) #fff !important;
}

.hierarchyContainer nav {
  border-bottom: 1px solid var(--mediumOrng);
}
