/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */

.cardheader {
  padding: 0 !important;
}
.cardheaderTxt {
  margin-left: 10px !important;
}

.accCard {
  border: 1px solid #faccb6 !important;
}

.noRatingData {
  color: #bcbcbc !important;
}
