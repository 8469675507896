/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
.navdropdown {
  background: var(--orng) !important;
  height: 37.5px !important;
  border-radius: 4px !important;
}

.orange-btn {
  border-color: var(--orng) !important;
  background-color: var(--orng) !important;
  outline: none !important;
  box-shadow: none !important;
}
.adminnav.active,
.adminnav:hover,
.adminnav.focus {
  color: white !important;
  text-decoration: none !important;
  background-color: var(--orng) !important;
}

.signedInas {
  color: #cfe7ff !important;
  margin-right: 35px;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 10px var(--orng);
}
