/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */

.company-view-table {
  width: 90%;
  border-color: black;
}

.company-view-table tr {
  padding: 5px;
  border-color: black;
}

.company-view-table tbody tr.selectable:hover,
tbody tr.active {
  background-color: var(--mediumOrng) !important;
  color: black;
  cursor: pointer;
  display: table-row !important;
}

.filter-bar {
  margin: 10px;
}

.company-question-searchbox {
  width: 90%;
  margin-left: 0px;
  height: 35px;
}

input.company-question-searchbox:focus {
  border-color: var(--orng);
  border: 0;
}

.company-search-icon {
  line-height: 3.5em !important;
}

.rurd .rating-data-box {
  position: relative !important;
}

.company-view-table tbody tr.active {
  background-color: var(--mediumOrng) !important;
  color: black;
  display: table-row !important;
}

.company-search-icon {
  width: 2.6rem !important;
  line-height: 1.9rem !important;
}

.active-page {
  background-color: var(--orng) !important;
  color: white !important;
  border: 1px solid var(--orng) !important;
}

ul.pagination li a.page-link:focus {
  box-shadow: 0 var(--orng);
}

ul.pagination li.disabled,
ul.pagination li.disabled a {
  color: #ccc !important;
  cursor: default;
}

.mt-39 {
  margin-top: -39px;
}

input {
  outline: 0 !important;
}

.loader {
  color: var(--orng);
  margin-left: 45%;
  margin-top: 30%;
}
