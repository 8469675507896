/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
.customSelect {
  background-color: #fffcfb;
  border-width: 3px;
  border-color: #ffede0;
  color: var(--text-col);
  outline: 0 !important;
}
