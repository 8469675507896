/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
.loader {
  color: var(--orng);
  margin-left: 45%;
  margin-top: 10% !important;
  margin-bottom: 10% !important;
}

.listitems.list-group-item {
  cursor: default !important;
}
