/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */

.small-header {
  background-color: #878584;
  text-align: center;
  color: var(--white);
  font-weight: 450;
}

.search-div {
  margin-bottom: 10px;
}

.valueList-spinner {
  text-align: center;
  min-height: 30px !important;
}

.spinnerCol {
  color: var(--orng);
}

.scroll-lock {
  overflow: hidden;
  margin-right: 17px;
}

.item-list {
  overflow: auto;
}

.item-list-title {
  border-bottom: thick solid black;
  margin: 5px;
  padding-right: 100%;
}

.item-list-entry {
  padding-left: 5px;
  font-size: 1em;
}

.item-list-entry:nth-of-type(odd) {
  background-color: #f2f2f2;
}

.pagination-margin ul {
  margin: 10px 0 0 !important;
}
