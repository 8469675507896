/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
.login-box2 {
  display: flex;
  background-color: rgb(245, 245, 245);
  flex-flow: row wrap;
  border-radius: 10px;
  border: 5px ridge orange;
  box-shadow: 3px 3px 3px 3px #ccc;
  width: 20%;
  height: 30em;
  margin: auto;
  width: 20%;
  min-width: 300px;
  margin-top: 3%;
  align-items: flex-start;
  text-align: center;
  padding: 3%;
}

.login-box {
  display: flex;
  background: #e9f3f6;
  border-width: 0;
  border-radius: 0.25rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  margin-bottom: 1.5rem;
  margin: 0 auto;
  width: 333px;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.flex {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

@media (max-width: 991.98px) {
  .padding {
    padding: 1.5rem;
  }
}

@media (max-width: 767.98px) {
  .padding {
    padding: 1rem;
  }
}

.padding {
  padding: 5rem;
}

.w-150 {
  width: 150px;
}

.login-box-header {
  background-color: transparent;
  border-color: rgba(160, 175, 185, 0.15);
  background-clip: padding-box;
}

.card-body p:last-child {
  margin-bottom: 0;
}

.card-hide-body .card-body {
  display: none;
}

.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: #f54394;
}

.login-error {
  color: #ff3304;
  background-color: #f7e1d7;
  border-color: #f99d71;
}
