/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
.list-entry {
  padding: 10px;
  margin-left: 10px;
  font-size: 1.2em;
  background: rgb(245, 245, 243);
  border: 1px solid rgba(12, 12, 12, 0.8);
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.15s ease-out;
}
.list-entry:hover {
  background: rgb(60, 77, 80);
  color: white;
}
.list-entry:focus {
  outline: 0;
  box-shadow: 0 0 4px currentColor;
}

.bordered-text {
  border: 1px solid rgba(12, 12, 12, 0.8);
  border-radius: 10px;
  height: inherit - 50%;
  overflow: auto;
}
